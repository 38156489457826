.display-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.display-flex-row > [class*=flex-]:not(.display-flex-row):not(.no-padding-right) {
  padding-right: 10px;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-10p {
  flex-grow: 1;
  flex-basis: 10%;
  min-width: 3em;
}

.flex-20p {
  flex-grow: 1;
  flex-basis: 20%;
  min-width: 4em;
}

.flex-30p {
  flex-grow: 2;
  flex-basis: 30%;
  min-width: 5em;
}

.flex-40p {
  flex-grow: 2;
  flex-basis: 40%;
  min-width: 7em;
}

.flex-50p {
  flex-grow: 3;
  flex-basis: 50%;
  min-width: 10em;
}

.flex-60p {
  flex-grow: 3;
  flex-basis: 60%;
  min-width: 11em;
}

.flex-70p {
  flex-grow: 4;
  flex-basis: 70%;
  min-width: 12em;
}

.flex-80p {
  flex-grow: 4;
  flex-basis: 80%;
  min-width: 13em;
}

.flex-90p {
  flex-grow: 5;
  flex-basis: 90%;
  min-width: 15em;
}

.flex-100p {
  flex-grow: 5;
  flex-basis: 100%;
  min-width: 15em;
}

.largeur-min-100 {
  min-width: 100px;
}

.largeur-min-150 {
  min-width: 150px;
}

.largeur-min-200 {
  min-width: 200px;
}

.largeur-min-250 {
  min-width: 250px;
}

.largeur-min-300 {
  min-width: 300px;
}

.largeur-min-350 {
  min-width: 350px;
}

.largeur-min-350 {
  min-width: 350px;
}

.largeur-min-400 {
  min-width: 400px;
}

.largeur-min-450 {
  min-width: 450px;
}

.largeur-min-500 {
  min-width: 500px;
}

.largeur-max-100 {
  max-width: 100px;
}

.largeur-max-150 {
  max-width: 150px;
}

.largeur-max-200 {
  max-width: 200px;
}

.largeur-max-250 {
  max-width: 250px;
}

.largeur-max-300 {
  max-width: 300px;
}

.largeur-max-350 {
  max-width: 350px;
}

.largeur-max-350 {
  max-width: 350px;
}

.largeur-max-400 {
  max-width: 400px;
}

.largeur-max-450 {
  max-width: 450px;
}

.largeur-max-500 {
  max-width: 500px;
}

/* For some end user customization  */
.text-default-color {
  color: --text-color;
}

.text-primary-color {
  color: var(--primary);
}

.text-accent-color {
  color: var(--accent);
}

.text-warn-color {
  color: var(--warn);
}

.text-primary-lighter-color {
  color: var(--primary-lighter);
}

.text-accent-lighter-color {
  color: var(--accent-lighter);
}

.text-warn-lighter-color {
  color: var(--warn-lighter);
}

.text-primary-darker-color {
  color: var(--primary-darker);
}

.text-accent-darker-color {
  color: var(--accent-darker);
}

.text-warn-darker-color {
  color: var(--warn-darker);
}

.italique {
  font-style: italic;
}

.gras {
  font-weight: bold;
}

.souligne {
  text-decoration: underline;
}

.secondaire {
  opacity: 0.7;
}

.parenthese::before {
  content: " ( ";
}
.parenthese::after {
  content: " ) ";
}

formly-field-mat-radio + .mat-form-field-label-wrapper {
  top: -1em;
}
formly-field-mat-radio + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.25em) scale(1) !important;
  width: 100% !important;
  font-size: 16px !important;
}

:root .mat-form-field .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  overflow: visible;
  margin-top: 0.2em;
}
:root formly-wrapper-mat-form-field .mat-form-field-subscript-wrapper,
:root formly-wrapper-mat-form-field .mat-form-field-underline {
  position: absolute;
}
:root .mat-vertical-stepper-header {
  padding: 10px 2px;
}
:root .mat-vertical-content-container {
  margin-left: 13px;
}
:root .mat-vertical-content {
  padding: 0 15px 15px;
}

.bloc-form-title-bar {
  color: var(--foreground-color-text);
  font-size: 18px;
  line-height: 18px;
  vertical-align: middle;
  margin: 10px 0 5px -10px;
}
.bloc-form-title-bar mat-icon {
  vertical-align: middle;
}

.bloc-form {
  margin: 0 10px 10px 0;
  border-radius: 5px;
}
.bloc-form > .bloc-form-title-bar {
  background: var(--background-color-app-bar);
  color: var(--foreground-color-text);
  border-bottom: 1px solid var(--foreground-color-divider);
  width: 100%;
  margin: 0;
  padding: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
}
.bloc-form .bloc-form-content {
  padding: 16px 16px 0px 16px;
}
.bloc-form .bloc-form-content .mat-radio-label .mat-form-field-label {
  transform: scale(1);
}