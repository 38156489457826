// @TODO: not sure if we better keep this css or factorize with icodomino class directly ...
i.icon {
  display: inline-block;
  vertical-align: middle;

  // Base size values, adjust on each page or element as needed
  width: 24px;
  // height: 24px;
  font-size: 24px;
}

// Doesn't fit all case (looks weird on home with colored background) => @TODO: move to specific (user board for now)
.mat-card-header.with-icon .mat-card-header-text {
  margin-left: 5px;
}

.mat-expansion-panel-header.with-icon {
  padding-left: 12px;
}
