@use '@angular/material'as mat;
@use 'sass:math';
@use 'sass:map';

@mixin include-variants($theme) {
  @include addVariantColor('primary', 'lighter', $theme);
  @include addVariantColor('primary', 'darker', $theme);

  @include addVariantColor('accent', 'lighter', $theme);
  @include addVariantColor('accent', 'darker', $theme);

  @include addVariantColor('warn', 'lighter', $theme);
  @include addVariantColor('warn', 'darker', $theme);
}


// Mixin permettant d'utiliser les couleurs de contraste (lighter / darker)
// plus facilement dans les composants 'Angular material'
// via l'utilisation de l'attribut [color]
// Exemple : <mat-toolbar color="primary-lighter">
@mixin addVariantColor($colorName, $colorVariant, $theme) {
  $color: map.get($theme, $colorName);

  // Options (for <mat-select>)
  .mat-#{$colorName}-#{$colorVariant} .mat-option.mat-selected:not(.mat-option-disabled) {
    color: mat.get-color-from-palette($color, $colorVariant);
  }


  // For checkbox
  .mat-#{$colorName}-#{$colorVariant} .mat-pseudo-checkbox-checked,
  .mat-#{$colorName}-#{$colorVariant} .mat-pseudo-checkbox-indeterminate {
    background: mat.get-color-from-palette($color, $colorVariant);
  }

  .mat-checkbox-indeterminate,
  .mat-checkbox-checked {
    &.mat-#{$colorName}-#{$colorVariant} .mat-checkbox-background {
      background-color: mat.get-color-from-palette($color, $colorVariant);
    }
  }

  .mat-checkbox:not(.mat-checkbox-disabled) {
    &.mat-#{$colorName}-#{$colorVariant} .mat-checkbox-ripple .mat-ripple-element {
      background-color: mat.get-color-from-palette($color, $colorVariant);
    }
  }

  // For Buttons
  .mat-button,
  .mat-icon-button,
  .mat-stroked-button {
    color: inherit;
    background: transparent;

    // @include _mat-button-theme-property($theme, 'color', ...);
    &.mat-#{$colorName}-#{$colorVariant} {
      color: mat.get-color-from-palette($color, $colorVariant);
    }

    // @mixin _mat-button-focus-overlay-color($theme) {
    &.mat-#{$colorName}-#{$colorVariant} .mat-button-focus-overlay {
      background-color: mat.get-color-from-palette($color, $colorVariant);
    }
  }

  .mat-flat-button,
  .mat-raised-button,
  .mat-fab,
  .mat-mini-fab {
    &.mat-#{$colorName}-#{$colorVariant} {
      // @include _mat-button-theme-property($theme, 'color', ...-contrast);
      color: mat.get-color-from-palette($color, #{$colorVariant}-contrast);
      // @include _mat-button-theme-property($theme, 'background-color', ...);
      background-color: mat.get-color-from-palette($color, #{$colorVariant});
    }

    // @include _mat-button-ripple-color($theme, ...-contrast);
    &.mat-#{$colorName}-#{$colorVariant} .mat-ripple-element {
      background-color: mat.get-color-from-palette($color, #{$colorVariant}-contrast, 0.1);
    }
  }


  // For Chips
  .mat-chip.mat-standard-chip.mat-chip-selected {
    &.mat-#{$colorName}-#{$colorVariant} {
      background-color: mat.get-color-from-palette($color, $colorVariant);
      color: mat.get-color-from-palette($color, #{$colorVariant}-contrast);

      .mat-chip-remove {
        color: mat.get-color-from-palette($color, #{$colorVariant}-contrast);
        opacity: 0.4;
      }

      .mat-ripple-element {
        background: mat.get-color-from-palette($color, #{$colorVariant}-contrast, 0.1);
      }
    }
  }


  // For Icons
  .mat-icon {
    &.mat-#{$colorName}-#{$colorVariant} {
      color: mat.get-color-from-palette($color, $colorVariant);
    }
  }


  // For radio buttons
  .mat-radio-button {
    &.mat-#{$colorName}-#{$colorVariant} {
      &.mat-radio-checked .mat-radio-outer-circle {
        border-color: mat.get-color-from-palette($color, $colorVariant);
      }

      .mat-radio-inner-circle,
      .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
      &.mat-radio-checked .mat-radio-persistent-ripple,
      &:active .mat-radio-persistent-ripple {
        background-color: mat.get-color-from-palette($color, $colorVariant);
      }
    }
  }

  // For toolbar
  .mat-toolbar {
    &.mat-#{$colorName}-#{$colorVariant} {
      background: mat.get-color-from-palette($color, $colorVariant);
      color: mat.get-color-from-palette($color, #{$colorVariant}-contrast);
    }
  }

  // For slide-toggle
  .mat-slide-toggle {
    &.mat-#{$colorName}-#{$colorVariant} {
      $thumb-unchecked-hue: if(map_get($theme, is-dark), 400, 50);
      $thumb-checked-hue: $colorVariant;

      &.mat-checked {
        .mat-slide-toggle-thumb {
          background-color: mat.get-color-from-palette($color, $thumb-checked-hue);
        }

        .mat-slide-toggle-bar {
          // Opacity is determined from the specs for the selection controls.
          // See: https://material.io/design/components/selection-controls.html#specs
          background-color: mat.get-color-from-palette($color, $thumb-checked-hue, 0.54);
        }

        .mat-ripple-element {
          // Set no opacity for the ripples because the ripple opacity will be adjusted dynamically
          // based on the type of interaction with the slide-toggle (e.g. for hover, focus)
          background-color: mat.get-color-from-palette($color, $thumb-checked-hue);
        }
      }
    }
  }

  // For slider
  .mat-#{$colorName}-#{$colorVariant} {

    .mat-slider-track-fill,
    .mat-slider-thumb,
    .mat-slider-thumb-label {
      background-color: mat.get-color-from-palette($color, $colorVariant);
    }

    .mat-slider-thumb-label-text {
      color: mat.get-color-from-palette($color, #{$colorVariant}-contrast);
    }
  }
}

// @NB: copied directly from Material, can no longer import it since it's now a private mixin ...
// Applies a floating label above the form field control itself.
@mixin my-label-floating($font-scale, $infix-padding, $infix-margin-top) {
  transform: translateY(-$infix-margin-top - $infix-padding) scale($font-scale);
  width: math.div(100%, $font-scale);
}

// Override Material native styles (required to be here if based on theme / config)
@mixin material-override($config) {

  // Uniformisation de la hauteur des select et des input (alignement plus harmonieux)
  .mat-input-element {
    height: mat.font-size($config, input) + 2px;
  }

  mat-select {
    height: mat.font-size($config, input) + 2px;
  }

  .mat-form-field-appearance-outline {
    $line-height: mat.line-height($config, input);
    $subscript-font-scale: 0.75;
    $infix-padding: 0.65em;
    $infix-margin-top: 1em * $line-height * $subscript-font-scale;
    $outline-appearance-label-offset: -0.25em;

    .mat-form-field-infix {
      padding: $infix-padding 0 $infix-padding 0;
    }

    .mat-form-field-label {
      top: $infix-margin-top + $infix-padding;
      margin-top: $outline-appearance-label-offset;
    }

    &.mat-form-field-can-float {

      &.mat-form-field-should-float .mat-form-field-label,
      .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
        @include my-label-floating($subscript-font-scale, $infix-padding + $outline-appearance-label-offset, $infix-margin-top);
      }
    }
  }

  // reduire un peu la heuteur des champs "FILL"" :
  // => copie partielle de @mixin mat-form-field-fill-typography($config)
  .mat-form-field-appearance-fill {
    $infix-padding-top: 0.35em; // ex 0.25em
    $infix-padding-bottom: 0.45em; // ex 0.75em

    .mat-form-field-infix {
      padding: $infix-padding-top 0 $infix-padding-bottom 0;
    }
  }
}

@mixin declare-theme-vars($theme, $config) {

  $primary: map.get($theme, 'primary');
  $accent: map.get($theme, 'accent');
  $warn: map.get($theme, 'warn');

  $foreground: map.get($theme, 'foreground');
  $background: map.get($theme, 'background');

  --primary: #{mat.get-color-from-palette($primary)};
  --accent: #{mat.get-color-from-palette($accent)};
  --warn: #{mat.get-color-from-palette($warn)};
  --primary-contrast: #{mat.get-color-from-palette($primary, default-contrast)};
  --accent-contrast: #{mat.get-color-from-palette($accent, default-contrast)};
  --warn-contrast: #{mat.get-color-from-palette($warn, default-contrast)};

  --primary-lighter: #{mat.get-color-from-palette($primary, lighter)};
  --accent-lighter: #{mat.get-color-from-palette($accent, lighter)};
  --warn-lighter: #{mat.get-color-from-palette($warn, lighter)};
  --primary-lighter-contrast: #{mat.get-color-from-palette($primary, lighter-contrast)};
  --accent-lighter-contrast: #{mat.get-color-from-palette($accent, lighter-contrast)};
  --warn-lighter-contrast: #{mat.get-color-from-palette($warn, lighter-contrast)};

  --primary-darker: #{mat.get-color-from-palette($primary, darker)};
  --accent-darker: #{mat.get-color-from-palette($accent, darker)};
  --warn-darker: #{mat.get-color-from-palette($warn, darker)};
  --primary-darker-contrast: #{mat.get-color-from-palette($primary, darker-contrast)};
  --accent-darker-contrast: #{mat.get-color-from-palette($accent, darker-contrast)};
  --warn-darker-contrast: #{mat.get-color-from-palette($warn, darker-contrast)};

  --text-color: #{mat.get-color-from-palette($foreground, text)};
  --border-color: #{mat.get-color-from-palette($foreground, divider)};
  --icon-color: #{mat.get-color-from-palette($foreground, icon)};
  --background-color: #{mat.get-color-from-palette($background, background)};
  --hover-background-color: #{mat.get-color-from-palette($background, hover)};
  --dialog-background-color: #{mat.get-color-from-palette($background, dialog)};

  --foreground-color-divider: #{mat.get-color-from-palette($foreground, 'divider')};
  --foreground-color-text: #{mat.get-color-from-palette($foreground, 'text')};

  --background-color-app-bar: #{mat.get-color-from-palette($background, app-bar)};
}

@mixin styles-for-theme($theme, $config) {

  $primary: map.get($theme, 'primary');
  $background: map.get($theme, 'background');

  .overlay-container {
    background: mat.get-color-from-palette($primary, 'default', 0.1);
  }

  .sticky-action-bar {
    background: mat.get-color-from-palette($background, 'app-bar', 0.9);
  }
}
