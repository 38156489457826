@use '@angular/material' as mat;
@use 'vars';
@use 'funcs';
@use 'sass:map';

// UNE SEULE FOIIIIIS
@include mat.core(vars.$typography);

@mixin portail-theme($theme, $config) {
  @include mat.all-component-themes($theme);
  @include funcs.include-variants($theme);
  @include funcs.declare-theme-vars($theme, $config);
  @include funcs.styles-for-theme($theme, $config);
}

.default-theme {

  @include funcs.material-override(vars.$typography);

  $theme: mat.define-light-theme(vars.$primary, vars.$accent, vars.$warn);
  @include portail-theme($theme, vars.$typography);
}
