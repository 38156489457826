.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.timepicker .timepicker__header {
  background-color: var(--timepicker-primary) !important;
}
.timepicker .clock-face__clock-hand {
  background-color: var(--timepicker-primary) !important;
}
.timepicker .clock-face__clock-hand::after {
  background-color: var(--timepicker-primary) !important;
}
.timepicker .clock-face__clock-hand::before {
  border-color: var(--timepicker-primary) !important;
}
.timepicker .clock-face__number > span.active {
  background-color: var(--timepicker-primary) !important;
}
.timepicker button:not(.timepicker-dial__item) {
  color: var(--timepicker-primary) !important;
}