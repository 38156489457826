mat-dialog-container mat-toolbar {
  margin: -24px -24px 0 -24px !important;
  width: unset !important;
}
mat-dialog-container mat-toolbar.mat-dialog-title {
  display: flex;
}
mat-dialog-container mat-toolbar.mat-dialog-title .title {
  flex: 1;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}
mat-dialog-container .mat-dialog-actions {
  place-content: center center;
  margin: 0 -24px -24px -24px;
  padding: 0 24px;
  background-color: var(--dialog-background-color);
  border-top: 1px solid var(--border-color);
}
mat-dialog-container .mat-dialog-actions button {
  margin: 8px;
}
mat-dialog-container .mat-dialog-content,
mat-dialog-container [mat-dialog-content] {
  max-height: 75vh;
}

.full-screen-dialog {
  max-width: 100% !important;
  width: 100% !important;
}
.full-screen-dialog .mat-dialog-title {
  font-size: 15px;
}
.full-screen-dialog .mat-dialog-container {
  border-radius: 0;
}

.dialog-large {
  min-width: 500px;
  min-height: 500px;
}

.theme-wrapper .mat-snack-bar-container {
  max-width: 90vw;
}