@use '@angular/material'as mat;

@import "mixins";
@import "./assets/icons.scss";
@import './assets/icodomino/style.css';
@import "@fortawesome/fontawesome-free/css/all.min.css";

@import "swiper/css";
@import "swiper/css/effect-fade";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "~tippy.js/themes/translucent.css";

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
}

.cc-window {
  color: rgb(255, 255, 255);
  background-color: rgb(57, 58, 66);
}

pdf-viewer {
  display: block;
  min-height: 50vh;
}

:root {
  --success: #4BB543;
  --success-lighter: #7FE187;
  --success-darker: #00C851;

  --header-height: 64px;
  --subheader-height: 48px;

  @media (max-width: 959px) {
    --header-height: 46px;
    --subheader-height: 42px;
  }

  --swiper-pagination-bullet-size: 10px;
  --swiper-navigation-size: 30px;
}

.default-theme,
.custom-theme {
  --swiper-theme-color: var(--primary);

  // FullCalendar
  --fc-page-bg-color: var(--background-color);

  --fc-event-bg-color: var(--accent);
  --fc-event-text-color: var(--accent-contrast);
  --fc-event-border-color: var(--accent);
}

html,
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  height: 100%;
}

body {
  @include thin-scrollbar;
}

* {
  box-sizing: border-box;

  &:active,
  :focus {
    outline: none !important;
  }
}

// Ajustements de Material en CSS pur
// @NB: précédemment était fait en modifiant les variables globales, mais plus possible, car les vars sont maintenant "scopées", merci Sass
.mat-tooltip {
  font-size: inherit !important;
}

.mat-tooltip-handset {
  font-size: inherit !important;
}

.tox .tox-collection--list .tox-collection__item {
  width: 190px;
}

.mat-radio-button {
  margin-right: 10px;
}

.mat-slide-toggle-label {
  height: initial !important;
}

// As in Bootstrap "reboot" CSS, and exists in Angular via [hidden] property ..
// Better than [ngStyle]="display: none", which gets overriden by fxFlex (even from children ..)
[hidden],
.hidden {
  display: none !important;
}


.flex-1 {
  flex: 1;
}


.page-container {
  margin: 10px 20px;
}

.parameters-btn-group {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  place-content: center flex-end;
  align-items: center;
  position: sticky;
  top: 3px;
  z-index: 100;
  margin: 5px 0;

  button {
    margin: auto 5px;
  }
}

.thin-scrollbar {
  @include thin-scrollbar;

  &.mat-drawer>.mat-drawer-inner-container {
    height: unset;
    overflow: unset;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mat-vertical-stepper-header {
  padding: 7px !important;
}


@media screen and (max-width: 680px) {

  .mat-vertical-stepper-header .mat-step-icon {
    height: 24px;
    width: 24px;
  }

  .mat-horizontal-content-container {
    padding: 0 !important;
  }

  // .full-screen{
  //   min-width: 100vw !important;
  //   height: 100vh !important;
  // }

  // .mat-dialog-container{
  //   padding: 3px !important;
  // }

  .mat-expansion-panel .mat-expansion-panel-body {
    padding: 0 2px 10px 2px;
  }

  .mat-card-content {
    margin: 2px !important;
  }

  .mat-card-header {
    padding: 5px !important;
  }
}

.mat-progress-spinner.icon-loader,
.app-loader.icon-loader {
  display: inline-block;
  width: 24px;
}

.mat-tooltip {
  &.tooltip-pre-line {
    white-space: pre-line;
  }

  &.tooltip-large {
    max-width: 550px;
  }
}

.mat-form-field.no-hint>.mat-form-field-wrapper {
  margin-bottom: -1em;
}

.custom-theme .mat-form-field-appearance-outline.small-outline .mat-form-field-infix {
  padding-top: .25em;
}

.button-group {
  button {
    margin: auto 5px;
  }
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.cdk-overlay-backdrop.light-backdrop {
  opacity: 0.5;
}

.cdk-drag-handle {
  cursor: move;
}

// Ok because redundant in several places (@TODO: but maybe make it optional ? with a class on parent checkbox)
.mat-checkbox-layout {
  white-space: normal !important;
}

.section-title {
  display: flex;
  place-items: center;

  .icon,
  .mat-icon {
    margin-right: 5px;
  }
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 100;
}

.height-full-minus-header {
  height: calc(100vh - var(--header-height, 64px));
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.items-center {
  place-items: center;
}

.items-start {
  place-items: start;
}

.content-center {
  place-content: center;
}

.content-start {
  place-content: flex-start;
}

.content-end {
  place-content: flex-end;
}

.self-start {
  place-self: start;
}

.allow-cr-in-tooltip {
  white-space: pre;
}

.spacer {
  flex: 1;
}

// because we want some priority for these classes, at least over default theme ones
:root body .theme-wrapper {
  .primary {
    color: var(--primary);
  }

  .accent {
    color: var(--accent);
  }

  .warn {
    color: var(--warn);
  }

  .primary-bg {
    background: var(--primary);
    color: var(--primary-contrast);
  }

  .accent-bg {
    background: var(--accent);
    color: var(--accent-contrast);
  }

  .warn-bg {
    background: var(--warn);
    color: var(--warn-contrast);
  }

  // Lighter colors
  .primary-lighter {
    color: var(--primary-lighter);
  }

  .accent-lighter {
    color: var(--accent-lighter);
  }

  .warn-lighter {
    color: var(--warn-lighter);
  }

  .primary-lighter-bg {
    background: var(--primary-lighter);
    color: var(--primary-lighter-contrast)
  }

  .accent-lighter-bg {
    background: var(--accent-lighter);
    color: var(--accent-lighter-contrast)
  }

  .warn-lighter-bg {
    background: var(--warn-lighter);
    color: var(--warn-lighter-contrast)
  }

  // Darker colors
  .primary-darker {
    color: var(--primary-darker);
  }

  .accent-darker {
    color: var(--accent-darker);
  }

  .warn-darker {
    color: var(--warn-darker);
  }

  .primary-darker-bg {
    background: var(--primary-darker);
    color: var(--primary-darker-contrast);
  }

  .accent-darker-bg {
    background: var(--accent-darker);
    color: var(--accent-darker-contrast);
  }

  .warn-darker-bg {
    background: var(--warn-darker);
    color: var(--warn-darker-contrast);
  }

  .success-color {
    color: var(--success);
  }

  .success-bg {
    background-color: var(--success);
    color: white;
  }

  .success-lighter-color {
    color: var(--success-lighter);
  }

  .success-lighter-bg {
    background-color: var(--success-lighter);
    color: black;
  }

  .success-darker-color {
    color: var(--success-darker);
  }

  .success-darker-bg {
    background-color: var(--success-darker);
    color: white;
  }
}

.border {
  border: 1px solid var(--foreground-color-divider);
}

.cdk-overlay-container.theme-wrapper .tippy-box {
  background-color: mat.get-color-from-palette(mat.$grey-palette, 700, 0.9) !important;

  .tippy-arrow {
    color: mat.get-color-from-palette(mat.$grey-palette, 700, 0.9);
  }
}

button.mat-icon-button.close-dialog {
  margin-left: 8px;
  margin-right: -8px;
}

.reply:hover,
.close-dialog:hover {
  background-color: var(--primary-lighter);
}

.mat-paginator-range-label {
  margin: 0 5px 0 5px;
}

.mat-tooltip {
  background: mat.get-color-from-palette(mat.$grey-palette, 700, 0.9);
}

mat-card-header {
  color: var(--primary-darker);
}

#card-header-home {
  background-color: var(--primary-lighter);
}

a {
  color: currentColor;
  position: relative;

  text-decoration: none;

  &:not(.no-underline-animation, .cc-dismiss) {
    text-decoration: none !important;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background-color: currentColor;
      visibility: visible;
      transform: scaleX(0);
      transition: all 0.2s ease-in-out 0s;
      box-shadow: 0px 2px 7px 0px currentColor;
    }

    &:hover {
      text-decoration: none;

      &:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
}

.timepicker {
  --clock-hand-color: var(--accent);
  --dial-background-color: var(--primary);
}

.main-content-scroll {
  // flex: 1;
  margin: 3px 5px;
  padding: 5px;
  overflow: auto;
  height: calc(100% - 10px);
}

app-topbar-actions button {
  margin: 0 3px !important;
}

// Force mat-stepper background-color to general background-color
.custom-theme .mat-stepper-horizontal,
.custom-theme .mat-stepper-vertical,
.custom-theme.dark-theme .mat-stepper-horizontal,
.custom-theme.dark-theme .mat-stepper-vertical {
  background-color: var(--background-color) !important;
}


button.button-with-autosubmit-timer {

  display: flex;
  z-index: 500;
  padding-right: 48px;


  .auto-submit-timer {
    display: inline flex;
    place-items: center;
    place-content: center;
    margin: 0px 0px 0px 8px;
    border-radius: 50%;
    align-self: center;
    position: absolute;
    top: calc(50% - 16px);
    right: 2px;

    span.counter {
      position: absolute;
      font-size: 12px;
    }

    .auto-submit-flashing {
      position: absolute;
      bottom: 0px;
      top: 0px;
      left: 0px;
      right: 0px;

      &::after {
        position: absolute;
        content: '';
        bottom: -5px;
        top: -5px;
        left: -5px;
        right: -5px;
        background-color: transparent;
        border-radius: 50%;
        border: 4px solid currentColor;
        margin: 0px;
        opacity: 0;
        transform-origin: center;
        animation: auto-submit-timer-anim 2000ms linear 200ms infinite;
      }

      &::before {
        position: absolute;
        content: '';
        bottom: -5px;
        top: -5px;
        left: -5px;
        right: -5px;
        background-color: transparent;
        border-radius: 50%;
        border: 4px solid currentColor;
        margin: 0px;
        opacity: 0.3;
        transform-origin: center;
        animation: auto-submit-timer-anim 2000ms linear 1200ms infinite;
      }

      &.more-speed::after {
        animation: auto-submit-timer-anim 1000ms linear 200ms infinite;
      }

      &.more-speed::before {
        animation: auto-submit-timer-anim 1000ms linear 700ms infinite;
      }

    }
  }

  @keyframes auto-submit-timer-anim {
    from {
      margin: 5px;
      opacity: 0.8;
    }

    to {
      margin: -20px;
      opacity: 0;
    }
  }

}


.mat-tooltip.autosubmit-timer-tooltip.mat-tooltip-show {
  font-size: 1.1em !important;
  white-space: pre-line;
  min-width: 320px;
}


// fix material nonsense ?

// .mat-button-wrapper {
//   display: flex;
//   place-items: center;

//   >* {
//     vertical-align: unset !important;
//   }
// }

.section-admin-container {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  z-index: 0;
  flex-grow: 1;
}

.section-admin-title {
  padding-bottom: .8rem;
  font-size: 1.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .app-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}

.section-admin-subtitle {
  display: flex;
  place-items: center;
  font-weight: bold;
  font-size: 1.05rem;
  margin-top: 20px;
  margin-bottom: 5px;

  .app-icon {
    width: 24px;
    height: 24px;
    margin-right: 2px;
  }
}
