.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.timepicker {

  .timepicker__header {
    background-color: var(--timepicker-primary) !important;
  }

  .clock-face__clock-hand {
    background-color: var(--timepicker-primary) !important;

    &::after {
      background-color: var(--timepicker-primary) !important;
    }

    &::before {
      border-color: var(--timepicker-primary) !important;
    }
  }

  .clock-face__number {
    >span.active {
      background-color: var(--timepicker-primary) !important;
    }
  }

  button:not(.timepicker-dial__item) {
    color: var(--timepicker-primary) !important;
  }
}
