.fx-flex {
  display: flex !important;
  box-sizing: border-box !important;
}

.fx-inline-flex {
  display: inline-flex !important;
  box-sizing: border-box !important;
}

.fx-direction-row {
  display: flex !important;
  flex-direction: row !important;
  box-sizing: border-box !important;
}

.fx-direction-col {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
}

.fx-wrap {
  flex-wrap: wrap !important;
}

.fx-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (max-width: 599px) {
  .fx-direction-col-xs {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 959px) {
  .fx-direction-col-sm {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 1279px) {
  .fx-direction-col-md {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 1919px) {
  .fx-direction-col-lg {
    flex-direction: column !important;
  }
}

@media screen and (max-width: 599px) {
  .fx-direction-row-xs {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 959px) {
  .fx-direction-row-sm {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 1279px) {
  .fx-direction-row-md {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 1919px) {
  .fx-direction-row-lg {
    flex-direction: row !important;
  }
}

.fx-flex-initial {
  flex: initial !important;
}

.fx-flex-auto {
  flex: auto !important;
}

.fx-flex-1 {
  flex: 1 !important;
}

.fx-flex-2 {
  flex: 2 !important;
}

.fx-flex-3 {
  flex: 3 !important;
}

.fx-flex-4 {
  flex: 4 !important;
}

.fx-flex-5 {
  flex: 5 !important;
}

.fx-flex-none {
  flex: none !important;
}

.fx-flex-nogrow {
  flex: 0 1 auto !important;
}

.fx-flex-noshrink {
  flex: 1 0 auto !important;
}

.fx-flex-grow {
  flex: 1 1 100% !important;
}

.fx-flex-grow25 {
  flex: 0 1 25% !important;
}

.fx-flex-grow50 {
  flex: 0 1 50% !important;
}

.fx-flex-grow75 {
  flex: 0 1 75% !important;
}

.fx-justify-start {
  justify-content: flex-start !important;
}

.fx-justify-end {
  justify-content: flex-end !important;
}

.fx-justify-center {
  justify-content: center !important;
}

.fx-justify-between {
  justify-content: space-between !important;
}

.fx-justify-around {
  justify-content: space-around !important;
}

.fx-justify-evenly {
  justify-content: space-evenly !important;
}

.fx-content-start {
  align-content: flex-start !important;
}

.fx-content-end {
  align-content: flex-end !important;
}

.fx-content-center {
  align-content: center !important;
}

.fx-content-stretch {
  align-content: stretch !important;
}

.fx-content-between {
  align-content: space-between !important;
}

.fx-content-around {
  align-content: space-around !important;
}

.fx-content-evenly {
  align-content: space-evenly !important;
}

.fx-alignItem-start {
  align-items: flex-start !important;
}

.fx-alignItem-end {
  align-items: flex-end !important;
}

.fx-alignItem-center {
  align-items: center !important;
}

.fx-alignItem-stretch {
  align-items: stretch !important;
}

.fx-alignItem-baseline {
  align-items: baseline !important;
}

.fx-alignSelf-start {
  align-self: flex-start !important;
}

.fx-alignSelf-end {
  align-self: flex-end !important;
}

.fx-alignSelf-center {
  align-self: center !important;
}

.fx-alignSelf-stretch {
  align-self: stretch !important;
}

.fx-alignSelf-baseline {
  align-self: baseline !important;
}

.fx-start {
  justify-content: flex-start !important;
  align-items: stretch !important;
  align-content: stretch !important;
}

.fx-start-start {
  justify-content: flex-start !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.fx-start-center {
  justify-content: flex-start !important;
  align-items: center !important;
  align-content: center !important;
}

.fx-start-end {
  justify-content: flex-start !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.fx-start-around {
  justify-content: flex-start !important;
  align-items: space-around !important;
  align-content: space-around !important;
}

.fx-start-between {
  justify-content: flex-start !important;
  align-items: space-between !important;
  align-content: space-between !important;
}

.fx-start-stretch-col {
  justify-content: flex-start !important;
  max-width: 100% !important;
}

.fx-start-stretch-row {
  justify-content: flex-start !important;
  max-height: 100% !important;
}

.fx-start-baseline {
  justify-content: flex-start !important;
  align-items: baseline !important;
  align-content: stretch !important;
}

.fx-center {
  justify-content: center !important;
  align-items: stretch !important;
  align-content: stretch !important;
}

.fx-center-start {
  justify-content: center !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.fx-center-center {
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.fx-center-end {
  justify-content: center !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.fx-center-around {
  justify-content: center !important;
  align-items: space-around !important;
  align-content: space-around !important;
}

.fx-center-between {
  justify-content: center !important;
  align-items: space-between !important;
  align-content: space-between !important;
}

.fx-center-stretch-col {
  justify-content: center !important;
  max-width: 100% !important;
}

.fx-center-stretch-row {
  justify-content: center !important;
  max-height: 100% !important;
}

.fx-center-baseline {
  justify-content: center !important;
  align-items: baseline !important;
  align-content: stretch !important;
}

.fx-end {
  justify-content: flex-end !important;
  align-items: stretch !important;
  align-content: stretch !important;
}

.fx-end-start {
  justify-content: flex-end !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.fx-end-center {
  justify-content: flex-end !important;
  align-items: center !important;
  align-content: center !important;
}

.fx-end-end {
  justify-content: flex-end !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.fx-end-around {
  justify-content: flex-end !important;
  align-items: space-around !important;
  align-content: space-around !important;
}

.fx-end-between {
  justify-content: flex-end !important;
  align-items: space-between !important;
  align-content: space-between !important;
}

.fx-end-stretch-col {
  justify-content: flex-end !important;
  max-width: 100% !important;
}

.fx-end-stretch-row {
  justify-content: flex-end !important;
  max-height: 100% !important;
}

.fx-end-baseline {
  justify-content: flex-end !important;
  align-items: baseline !important;
  align-content: stretch !important;
}

.fx-around {
  justify-content: space-around !important;
  align-items: stretch !important;
  align-content: stretch !important;
}

.fx-around-start {
  justify-content: space-around !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.fx-around-center {
  justify-content: space-around !important;
  align-items: center !important;
  align-content: center !important;
}

.fx-around-end {
  justify-content: space-around !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.fx-around-around {
  justify-content: space-around !important;
  align-items: space-around !important;
  align-content: space-around !important;
}

.fx-around-between {
  justify-content: space-around !important;
  align-items: space-between !important;
  align-content: space-between !important;
}

.fx-around-stretch-col {
  justify-content: space-around !important;
  max-width: 100% !important;
}

.fx-around-stretch-row {
  justify-content: space-around !important;
  max-height: 100% !important;
}

.fx-around-baseline {
  justify-content: space-around !important;
  align-items: baseline !important;
  align-content: stretch !important;
}

.fx-between {
  justify-content: space-between !important;
  align-items: stretch !important;
  align-content: stretch !important;
}

.fx-between-start {
  justify-content: space-between !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.fx-between-center {
  justify-content: space-between !important;
  align-items: center !important;
  align-content: center !important;
}

.fx-between-end {
  justify-content: space-between !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.fx-between-around {
  justify-content: space-between !important;
  align-items: space-around !important;
  align-content: space-around !important;
}

.fx-between-between {
  justify-content: space-between !important;
  align-items: space-between !important;
  align-content: space-between !important;
}

.fx-between-stretch-col {
  justify-content: space-between !important;
  max-width: 100% !important;
}

.fx-between-stretch-row {
  justify-content: space-between !important;
  max-height: 100% !important;
}

.fx-between-baseline {
  justify-content: space-between !important;
  align-items: baseline !important;
  align-content: stretch !important;
}

.fx-evenly {
  justify-content: space-evenly !important;
  align-items: stretch !important;
  align-content: stretch !important;
}

.fx-evenly-start {
  justify-content: space-evenly !important;
  align-items: flex-start !important;
  align-content: flex-start !important;
}

.fx-evenly-center {
  justify-content: space-evenly !important;
  align-items: center !important;
  align-content: center !important;
}

.fx-evenly-end {
  justify-content: space-evenly !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
}

.fx-evenly-around {
  justify-content: space-evenly !important;
  align-items: space-around !important;
  align-content: space-around !important;
}

.fx-evenly-between {
  justify-content: space-evenly !important;
  align-items: space-between !important;
  align-content: space-between !important;
}

.fx-evenly-stretch-col {
  justify-content: space-evenly !important;
  max-width: 100% !important;
}

.fx-evenly-stretch-row {
  justify-content: space-evenly !important;
  max-height: 100% !important;
}

.fx-evenly-baseline {
  justify-content: space-evenly !important;
  align-items: baseline !important;
  align-content: stretch !important;
}

.fx-gap-2 {
  gap: 2px !important;
}

.fx-gap-3 {
  gap: 3px !important;
}

.fx-gap-4 {
  gap: 4px !important;
}

.fx-gap-5 {
  gap: 5px !important;
}

.fx-gap-8 {
  gap: 8px !important;
}

.fx-gap-10 {
  gap: 10px !important;
}

.fx-gap-15 {
  gap: 15px !important;
}

.fx-gap-20 {
  gap: 20px !important;
}

.fx-gap-30 {
  gap: 30px !important;
}

.fx-gap-40 {
  gap: 40px !important;
}

.fx-gap-50 {
  gap: 50px !important;
}

.fx-fill {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-width: 100% !important;
  min-height: 100% !important;
}

.center-text {
  text-align: center !important;
}

.hide {
  display: none !important;
}

@media screen and (max-width: 599px) {
  .fx-hide-xs {
    display: none !important;
  }
}

@media screen and (min-width: 600px) {
  .fx-hide-gt-xs {
    display: none !important;
  }
}

@media screen and (max-width: 1279px) {
  .fx-hide-sm {
    display: none !important;
  }
}

@media screen and (min-width: 960px) {
  .fx-hide-gt-sm {
    display: none !important;
  }
}