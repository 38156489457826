@font-face {
  font-family: 'icodomino';
  src:  url('fonts/icodomino.eot?minn08');
  src:  url('fonts/icodomino.eot?minn08#iefix') format('embedded-opentype'),
    url('fonts/icodomino.ttf?minn08') format('truetype'),
    url('fonts/icodomino.woff?minn08') format('woff'),
    url('fonts/icodomino.svg?minn08#icodomino') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icodomino-"], [class*=" icodomino-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icodomino' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icodomino-absence:before {
  content: "\e900";
}
.icodomino-accueil-dom:before {
  content: "\e901";
}
.icodomino-accueil:before {
  content: "\e902";
}
.icodomino-activite:before {
  content: "\e903";
}
.icodomino-adresse:before {
  content: "\e904";
}
.icodomino-adulte:before {
  content: "\e905";
}
.icodomino-agrement-etablissement:before {
  content: "\e906";
}
.icodomino-agrement-principal:before {
  content: "\e907";
}
.icodomino-agrement-secondaire:before {
  content: "\e908";
}
.icodomino-allergie:before {
  content: "\e909";
}
.icodomino-allergie2:before {
  content: "\e90a";
}
.icodomino-annee-scolaire:before {
  content: "\e90b";
}
.icodomino-annotation:before {
  content: "\e90c";
}
.icodomino-antecedent-medical:before {
  content: "\e90d";
}
.icodomino-assmat:before {
  content: "\e90e";
}
.icodomino-attestation-fiscale:before {
  content: "\e90f";
}
.icodomino-auto:before {
  content: "\e910";
}
.icodomino-autorisation:before {
  content: "\e911";
}
.icodomino-autorise-chercher:before {
  content: "\e912";
}
.icodomino-autre:before {
  content: "\e913";
}
.icodomino-banque:before {
  content: "\e914";
}
.icodomino-bareme-facturation:before {
  content: "\e915";
}
.icodomino-birthday-cake:before {
  content: "\e916";
}
.icodomino-caf:before {
  content: "\e917";
}
.icodomino-classe:before {
  content: "\e918";
}
.icodomino-clavier:before {
  content: "\e919";
}
.icodomino-compte-bancaire:before {
  content: "\e91a";
}
.icodomino-compte-comptable:before {
  content: "\e91b";
}
.icodomino-constante:before {
  content: "\e91c";
}
.icodomino-contrat-entreprise:before {
  content: "\e91d";
}
.icodomino-copier-fichier:before {
  content: "\e91e";
}
.icodomino-courrier:before {
  content: "\e91f";
}
.icodomino-cours:before {
  content: "\e920";
}
.icodomino-crayon:before {
  content: "\e921";
}
.icodomino-creancier-sepa:before {
  content: "\e922";
}
.icodomino-creancier-sepa2:before {
  content: "\e923";
}
.icodomino-cross:before {
  content: "\e924";
}
.icodomino-csp:before {
  content: "\e925";
}
.icodomino-cycle-scolaire:before {
  content: "\e926";
}
.icodomino-date-range:before {
  content: "\e927";
}
.icodomino-dates-inscription:before {
  content: "\e928";
}
.icodomino-decoupage-rue:before {
  content: "\e929";
}
.icodomino-departement:before {
  content: "\e92a";
}
.icodomino-diplome:before {
  content: "\e92b";
}
.icodomino-discipline:before {
  content: "\e92c";
}
.icodomino-dispo:before {
  content: "\e92d";
}
.icodomino-document:before {
  content: "\e92e";
}
.icodomino-droit-modele-revenu:before {
  content: "\e92f";
}
.icodomino-droit-modele-revenu2:before {
  content: "\e930";
}
.icodomino-duree-arrondie:before {
  content: "\e931";
}
.icodomino-duree-reelle:before {
  content: "\e932";
}
.icodomino-ecole:before {
  content: "\e933";
}
.icodomino-edition:before {
  content: "\e934";
}
.icodomino-enfant:before {
  content: "\e935";
}
.icodomino-enseignant:before {
  content: "\e936";
}
.icodomino-etablissement:before {
  content: "\e937";
}
.icodomino-euro-fleche:before {
  content: "\e938";
}
.icodomino-evaluation:before {
  content: "\e939";
}
.icodomino-export-comptable:before {
  content: "\e93a";
}
.icodomino-export-financier:before {
  content: "\e93b";
}
.icodomino-export:before {
  content: "\e93c";
}
.icodomino-facturation:before {
  content: "\e93d";
}
.icodomino-famille:before {
  content: "\e93e";
}
.icodomino-fille:before {
  content: "\e93f";
}
.icodomino-formation:before {
  content: "\e940";
}
.icodomino-foyer:before {
  content: "\e941";
}
.icodomino-fusion-facture:before {
  content: "\e942";
}
.icodomino-garcon:before {
  content: "\e943";
}
.icodomino-garde-domicile:before {
  content: "\e944";
}
.icodomino-ged:before {
  content: "\e945";
}
.icodomino-ged2:before {
  content: "\e946";
}
.icodomino-gomme:before {
  content: "\e947";
}
.icodomino-grille-forfait:before {
  content: "\e948";
}
.icodomino-groupe-commission:before {
  content: "\e949";
}
.icodomino-groupe-section:before {
  content: "\e94a";
}
.icodomino-groupe:before {
  content: "\e94b";
}
.icodomino-handicap:before {
  content: "\e94c";
}
.icodomino-holiday:before {
  content: "\e94d";
}
.icodomino-horloge:before {
  content: "\e94e";
}
.icodomino-indice-facturation:before {
  content: "\e94f";
}
.icodomino-inscription-femme:before {
  content: "\e950";
}
.icodomino-inscription-fille:before {
  content: "\e951";
}
.icodomino-inscription-garcon:before {
  content: "\e952";
}
.icodomino-inscription-homme:before {
  content: "\e953";
}
.icodomino-inscription:before {
  content: "\e954";
}
.icodomino-jour-ferie-2:before {
  content: "\e955";
}
.icodomino-jour-ferie:before {
  content: "\e956";
}
.icodomino-lightbulb:before {
  content: "\e957";
}
.icodomino-liste-diffusion:before {
  content: "\e958";
}
.icodomino-liste-inscriptions:before {
  content: "\e959";
}
.icodomino-lock:before {
  content: "\e95a";
}
.icodomino-mail-account:before {
  content: "\e95b";
}
.icodomino-mail-config:before {
  content: "\e95c";
}
.icodomino-mail-send:before {
  content: "\e95d";
}
.icodomino-maladie:before {
  content: "\e95e";
}
.icodomino-man:before {
  content: "\e95f";
}
.icodomino-mandat-sepa:before {
  content: "\e960";
}
.icodomino-medecin:before {
  content: "\e961";
}
.icodomino-medicament:before {
  content: "\e962";
}
.icodomino-medicament2:before {
  content: "\e963";
}
.icodomino-mode-reglement:before {
  content: "\e964";
}
.icodomino-modele-facturation:before {
  content: "\e965";
}
.icodomino-modele-prestation:before {
  content: "\e966";
}
.icodomino-modele-revenu:before {
  content: "\e967";
}
.icodomino-modele-revenu2:before {
  content: "\e968";
}
.icodomino-motif-action:before {
  content: "\e969";
}
.icodomino-motif-arret:before {
  content: "\e96a";
}
.icodomino-motif-derogation:before {
  content: "\e96b";
}
.icodomino-motif-radiation:before {
  content: "\e96c";
}
.icodomino-motif-refus-derogation:before {
  content: "\e96d";
}
.icodomino-niveau-musical:before {
  content: "\e96e";
}
.icodomino-niveau-scolaire:before {
  content: "\e96f";
}
.icodomino-option:before {
  content: "\e970";
}
.icodomino-organisme:before {
  content: "\e971";
}
.icodomino-pai:before {
  content: "\e972";
}
.icodomino-parametrage-libre:before {
  content: "\e973";
}
.icodomino-partenaire:before {
  content: "\e974";
}
.icodomino-percent:before {
  content: "\e975";
}
.icodomino-periode:before {
  content: "\e976";
}
.icodomino-personne-autorisee:before {
  content: "\e977";
}
.icodomino-personnel:before {
  content: "\e978";
}
.icodomino-piece-euro:before {
  content: "\e979";
}
.icodomino-planning-enseignant:before {
  content: "\e97a";
}
.icodomino-planning-hebdomadaire:before {
  content: "\e97b";
}
.icodomino-planning-mensuel:before {
  content: "\e97c";
}
.icodomino-planning-quotidien:before {
  content: "\e97d";
}
.icodomino-planning-salle:before {
  content: "\e97e";
}
.icodomino-plus:before {
  content: "\e97f";
}
.icodomino-portail:before {
  content: "\e980";
}
.icodomino-portefeuille:before {
  content: "\e981";
}
.icodomino-poussette:before {
  content: "\e982";
}
.icodomino-pre-inscription:before {
  content: "\e983";
}
.icodomino-prelevement-sepa:before {
  content: "\e984";
}
.icodomino-presence:before {
  content: "\e985";
}
.icodomino-present:before {
  content: "\e986";
}
.icodomino-previsionnel:before {
  content: "\e987";
}
.icodomino-prevReel:before {
  content: "\e988";
}
.icodomino-profession:before {
  content: "\e989";
}
.icodomino-publipostage-categorie:before {
  content: "\e98a";
}
.icodomino-publipostage:before {
  content: "\e98b";
}
.icodomino-rapport-activite:before {
  content: "\e98c";
}
.icodomino-rapport-facturation:before {
  content: "\e98d";
}
.icodomino-rapport-facturation2:before {
  content: "\e98e";
}
.icodomino-rapport-reglement:before {
  content: "\e98f";
}
.icodomino-reel:before {
  content: "\e990";
}
.icodomino-regie:before {
  content: "\e991";
}
.icodomino-regime-alimentaire:before {
  content: "\e992";
}
.icodomino-regime:before {
  content: "\e993";
}
.icodomino-remise-banque:before {
  content: "\e994";
}
.icodomino-remise-contentieux:before {
  content: "\e995";
}
.icodomino-repas:before {
  content: "\e996";
}
.icodomino-repas2:before {
  content: "\e997";
}
.icodomino-reprise-solde-client:before {
  content: "\e998";
}
.icodomino-reste-a-payer:before {
  content: "\e999";
}
.icodomino-rubrique-facturation:before {
  content: "\e99a";
}
.icodomino-rubrique-planning:before {
  content: "\e99b";
}
.icodomino-rubrique-prestation:before {
  content: "\e99c";
}
.icodomino-salle:before {
  content: "\e99d";
}
.icodomino-scolaire:before {
  content: "\e99e";
}
.icodomino-secteur-scolaire:before {
  content: "\e99f";
}
.icodomino-secteur:before {
  content: "\e9a0";
}
.icodomino-service:before {
  content: "\e9a1";
}
.icodomino-situation-familiale:before {
  content: "\e9a2";
}
.icodomino-smartphone:before {
  content: "\e9a3";
}
.icodomino-sms:before {
  content: "\e9a4";
}
.icodomino-sommeil:before {
  content: "\e9a5";
}
.icodomino-source:before {
  content: "\e9a6";
}
.icodomino-suivi-medical:before {
  content: "\e9a7";
}
.icodomino-target:before {
  content: "\e9a8";
}
.icodomino-theme-animation:before {
  content: "\e9a9";
}
.icodomino-theme-reunion:before {
  content: "\e9aa";
}
.icodomino-tiers-payeur:before {
  content: "\e9ab";
}
.icodomino-tirelire:before {
  content: "\e9ac";
}
.icodomino-type-action:before {
  content: "\e9ad";
}
.icodomino-type-animation:before {
  content: "\e9ae";
}
.icodomino-type-derogation:before {
  content: "\e9af";
}
.icodomino-type-reunion:before {
  content: "\e9b0";
}
.icodomino-type-rubrique-facturation:before {
  content: "\e9b1";
}
.icodomino-type-rubrique-planning:before {
  content: "\e9b2";
}
.icodomino-upload:before {
  content: "\e9b3";
}
.icodomino-usager:before {
  content: "\e9b4";
}
.icodomino-vaccin:before {
  content: "\e9b5";
}
.icodomino-ville:before {
  content: "\e9b6";
}
.icodomino-virement-sepa:before {
  content: "\e9b7";
}
.icodomino-woman:before {
  content: "\e9b8";
}
.icodomino-zone:before {
  content: "\e9b9";
}
